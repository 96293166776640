import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
 Container, Col, Row, Card, CardBody, 
} from 'reactstrap';
import Select from 'react-select';
import ReportFilter, { ReportType } from '../../../shared/components/filter/ReportFilter';
import env from '../../../Env';
import { get, post } from '../../../utils/api/base';
import './index.scss';

const CreatePaymentReport = () => {
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState({ value: '', label: '' });
  const [reportName, setReportName] = useState('');
  const [isSystemReport, setIsSystemReport] = useState(false);
  const [filterValues, setFilterValues] = useState({});

  const history = useHistory();
  const { reportProps } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const endpoint = encodeURI(`${env.baseApiUrl}/api/reports/filter-values?reportType=${ReportType.Payment}`);

      get(endpoint)
        .then((res) => {
          setFilterValues(res.data);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const endpoint = encodeURI(`${env.baseApiUrl}/api/reports/clients`);

      get(endpoint)
        .then((res) => {
          setClientOptions(res.data.map(x => ({ ...x })));
        });
    };

    fetchData();
  }, []);

  const handleSubmit = (name, systemReport, selectedFilters) => {
    const queryParameters = selectedFilters.reduce((acc, { key, value }) => `${acc}${key}=${value}&`, '');

    const endpoint = encodeURI(`${env.baseApiUrl}/api/reports/${selectedClient.value}`);

    const body = JSON.stringify({ 
      name, 
      isSystemReport: systemReport, 
      queryParameters, 
      clientId: selectedClient.value,
      reportType: ReportType.Payment,
    });

    const fetchReportAndRedirect = async (clientId, reportId) => {
      const getReportByIdEndpoint = encodeURI(`${env.baseApiUrl}/api/reports/${clientId}/${reportId}`);

      get(getReportByIdEndpoint)
        .then((res) => {
          history.push(res.data.reportUrl);
          reportProps.reloadSidebar();
        });
    };

    post(endpoint, body)
      .then(response => fetchReportAndRedirect(selectedClient.value, response.data));
  };

  return (
    <div className="create-report">
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Create Payment Report</h3>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Row>
              <Col md={3}>
                <h5 className="page-title heading">Select client</h5>
                <Select
                  name="value"
                  value={selectedClient.value ? selectedClient : ''}
                  onChange={option => setSelectedClient(option)}
                  options={clientOptions.map(x => ({ value: x.id, label: x.name }))}
                  clearable={false}
                  closeOnSelect
                  removeSelected={false}
                  className="react-select"
                  placeholder="Select filter..."
                  classNamePrefix="react-select"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          {selectedClient.value && (
            <ReportFilter 
              filterValues={[]}
              queryFilters={{}}
              handleSubmit={handleSubmit}
              reportName={reportName}
              setReportName={setReportName}
              isSystemReport={isSystemReport}
              setIsSystemReport={setIsSystemReport}
              reportType={1}
            />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default CreatePaymentReport;
