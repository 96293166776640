import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
 Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, 
} from 'reactstrap';
import './AttendeeNoteModal.scss';
import { get, post } from '../../../../utils/api/base';
import env from '../../../../Env';

const AttendeeNoteModal = ({
 visible, toggleVisiblity, payceEventAttendeeId, updateHasNotes, 
}) => {
  const [notes, setNotes] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (payceEventAttendeeId) {
      setLoading(true);
      get(`${env.baseApiUrl}/api/attendees/${payceEventAttendeeId}/notes`)
        .then((res) => {
          setNotes(res.data);
          setLoading(false);
        });
    }
    return () => {
      setNotes();
    };
  }, [payceEventAttendeeId]);

  const closeModal = () => {
    toggleVisiblity(null);
  };

  const handleSubmit = () => {
    const body = JSON.stringify({ 
      notes,
    });

    post(`${env.baseApiUrl}/api/attendees/${payceEventAttendeeId}/notes`, body)
      .then(() => {
        updateHasNotes(notes !== null && notes !== '');
        closeModal();
      });
  };

  return (
    <div className="modal__wrapper">
      <Modal
        modalClassName="attendee-note-modal" 
        contentClassName="attendee-note-modal__content" 
        isOpen={visible} 
        toggle={closeModal}
      >
        <ModalHeader toggle={closeModal}>
          Event Attendee Notes
        </ModalHeader>
        <ModalBody>
          <div>
            {loading ? (
              <p>loading...</p>
            )
            : (
              <Input
                type="textarea" 
                value={notes} 
                placeholder="Enter the Attendee Notes" 
                onChange={event => setNotes(event.target.value)}
              />
          )}
          </div>         
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit}>Save</Button>{' '}
          <Button color="secondary" onClick={closeModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

AttendeeNoteModal.propTypes = {
  payceEventAttendeeId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  toggleVisiblity: PropTypes.func.isRequired,
  updateHasNotes: PropTypes.func.isRequired,
};

AttendeeNoteModal.defaultProps = {
  payceEventAttendeeId: null,
};

export default AttendeeNoteModal;
