import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProps } from '@/shared/prop-types/ReducerProps';
import moment from 'moment';
import ReactTableDnDBody from './ReactTableDnDBody';

const renderCell = (cell) => {
  switch (cell.column.Header) {
    case 'Event Name':
    case 'Engagement Name': {
      return cell.value.link.length > 0
      ? <a href={cell.value.link} target="_blank" rel="noopener noreferrer">{cell.value.value}</a>
      : cell.value.value;
    }
    case 'Date of Event':
    case 'End Date':
    case 'Services Received Date':
    case 'Expenses Submitted Date':
    case 'Payment Approved Date':
    case 'Contract Approved Date':
    case 'Sent for Payment Date':
    case 'Delegate Accepted Date':
    case 'Paid Date': 
    case 'Expenses Unlocked Date':
    case 'Invoice Submitted':
    case 'Contract Created Date':
    case 'Contract Issued Date':
    case 'Contract Signed Date':
    case 'Date Paid': {
      return cell.value ? moment(cell.value).format('DD/MM/YY') : '';
    }
    default: {
      return cell.render('Cell');
    }
  }
};

const ReactTableDefaultBody = ({ page, getTableBodyProps, prepareRow }) => (
  <tbody className="table table--bordered" {...getTableBodyProps()}>
    {!page.length && (
      <tr>
        <td><span>No rows</span></td>
      </tr>
    )}
    {page.map((row) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map(cell => (
            <td {...cell.getCellProps()}>
              {renderCell(cell)}
            </td>
          ))}
        </tr>
      );
    })}
  </tbody>
);

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page, getTableBodyProps, prepareRow, withDragAndDrop, updateDraggableData, theme,
}) => (
  <Fragment>
    {withDragAndDrop
      ? (
        <ReactTableDnDBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateDraggableData={updateDraggableData}
          theme={theme}
        />
      ) : (
        <ReactTableDefaultBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
        />
      )}
  </Fragment>
);

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
  theme: ThemeProps.isRequired,
};

export default connect(state => ({
  theme: state.theme,
}))(ReactTableBody);
