/* eslint-disable prefer-destructuring */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import debounce from 'lodash.debounce';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import PaginationContainer from './PaginationContainer';

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DataReactTable = ({
  reactTableData, 
  page, 
  setPage, 
  totalRecords, 
  loading,
  handleFiltersUpdate,
  filterValues,
  handleSort,
  sortedColumn,
}) => {
  const [rows, setData] = useState(reactTableData.tableRowsData);
  const [filters, setFilters] = useState({});

  const [isEditable] = useState(false);
  const [isResizable] = useState(false);
  const [isSortable] = useState(true);
  const [withDragAndDrop] = useState(false);
  const [withPagination] = useState(false);
  const [withSearchEngine] = useState(false);

  const updateDraggableData = (result) => {
    const items = reorder(
      rows,
      result.source.index,
      result.destination.index,
    );
    setData(items);
  };

  const updateEditableData = (rowIndex, columnId, value) => {
    setData(old => old.map((item, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return item;
    }));
  };

  const delay = 500;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilterUpdate = useCallback(debounce(handleFiltersUpdate, delay), [handleFiltersUpdate, delay]);

  const onFiltersUpdate = (key, value, filterType) => {
    const newFilters = { ...filters };
    newFilters[key] = { value, filterType };
    setFilters(newFilters);

    debouncedFilterUpdate(key, value, filterType);
  };

  const tableConfig = {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by First name...',
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <ReactTableBase
            key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
            columns={reactTableData.tableHeaderData}
            data={reactTableData.tableRowsData}
            updateEditableData={updateEditableData}
            updateDraggableData={updateDraggableData}
            tableConfig={tableConfig}
            loading={loading}
            filters={filters}
            handleFiltersUpdate={onFiltersUpdate}
            filterValues={filterValues}
            handleSort={handleSort}
            sortedColumn={sortedColumn}
          />
          {!loading && <PaginationContainer page={page} setPage={setPage} totalRecords={totalRecords} />}
        </CardBody>
      </Card>
    </Col>
  );
};

DataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  handleFiltersUpdate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterValues: PropTypes.object.isRequired,
  handleSort: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sortedColumn: PropTypes.object.isRequired,
};

export default DataReactTable;
