import React from 'react';
import PropTypes from 'prop-types';
import {
 Button,
} from 'reactstrap';

const AttendeeNoteButton = ({ setModalVisbility, payceEventAttendeeId, hasNotes }) => {
  const openModal = () => {
    setModalVisbility(payceEventAttendeeId);
  };

  return (    
    <Button onClick={openModal} color={hasNotes ? 'success' : 'secondary'}><span className="lnr lnr-pencil" /></Button>
  );
};

AttendeeNoteButton.propTypes = {
    setModalVisbility: PropTypes.func.isRequired,
    payceEventAttendeeId: PropTypes.string.isRequired,
    hasNotes: PropTypes.bool.isRequired,
};

export default AttendeeNoteButton;
