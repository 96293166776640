import React, {
 useState, useContext, createContext, 
} from 'react';
import PropTypes from 'prop-types';

const authContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext);

const checkIsAuthenticated = () => {
  const key = 'isAuthenticated';
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return false;
  }

  const item = JSON.parse(itemStr);

  if (new Date().getTime() > item.expiry) {
    localStorage.removeItem(key);

    return false;
  }

  const value = item.value === 'true';
  
  return value;
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(checkIsAuthenticated());

  const signin = () => {
    const item = {
      value: 'true',
      expiry: new Date().getTime() + (60000 * 20),
    };
    localStorage.setItem('isAuthenticated', JSON.stringify(item));
    
    setAuthenticated(true);
  };

  const signout = () => {
    localStorage.removeItem('isAuthenticated');
    setAuthenticated(false);
  };

  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
    isAuthenticated,
  };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

ProvideAuth.propTypes = {
  children: PropTypes.shape().isRequired,
};
