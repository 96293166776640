import React, { useState } from 'react';
import {
  Button, Card, CardBody, Col, Container, Input, Label, Row, 
} from 'reactstrap';
import Select from 'react-select';
import './ReportFilter.scss';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import CreateTableData, { CreatePaymentTableData } from '../../../containers/Tables/CreateData';
import SelectedFilters from './SelectedFilters';

export const ReportType = {
  Attendee: 0,
  Payment: 1,
};

const getSelectedFilters = (queryFilters, reportType) => {
  const { filters } = reportType === ReportType.Attendee ? CreateTableData() : CreatePaymentTableData();

  return Object.entries(queryFilters).reduce((acc, [queryFilterKey, queryFilterValues]) => {
    const currentFilters = (queryFilterValues?.value || []).map(({ value }) => (
      { 
        key: queryFilterKey, 
        filterType: queryFilterValues.filterType,
        value,
        Header: filters.find(x => x.key === queryFilterKey).Header,
      }
    ));

    return acc.concat(currentFilters);
  }, []);
};

const ReportFilter = ({
 filterValues, queryFilters, handleSubmit, reportName, setReportName, isSystemReport, setIsSystemReport, reportType,
}) => {
  const [tableHeaderData] = useState(CreateTableData().filters);

  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const filters = getSelectedFilters(queryFilters, reportType);
  const [selectedFilters, setSelectedFilters] = useState(filters);
  const [currentFilter, setCurrentFilter] = useState({ value: '' });

  const onFilterSelected = ({ value: dropdownOption }) => {
    const { filterType, Header } = tableHeaderData.find(x => x.key === dropdownOption);
    const previousKey = currentFilter.key;
    setCurrentFilter({
      key: dropdownOption, filterType, Header, previousKey, 
    });
  };

  const onRemoveFilter = (key, value) => {
    setSelectedFilters(selectedFilters.filter(x => x.key !== key || (x.key === key && x.value !== value)));
  };

  const onUpdateFilter = (key) => {
    setIsUpdating(true);
    setCurrentFilter(selectedFilters.find(x => x.key === key));
  };

  const onSaveReport = () => {
    handleSubmit(reportName, isSystemReport, selectedFilters);
  };

  const renderInput = ({ filterType }) => {
    switch (filterType) {
      case 'text': {
        return (
          <Input
            value={currentFilter?.value || ''}
            className="report-filter__input report-filter__form-component"
            onChange={event => setCurrentFilter({ ...currentFilter, value: event.target.value })}
            placeholder="Enter text..."
          />
        );
      }
      case 'dropdown': {
        const value = currentFilter?.value ? { value: currentFilter.value, label: currentFilter.value } : '';

        return (
          <>
            <Select
              name="value"
              value={value}
              onChange={option => setCurrentFilter({ ...currentFilter, value: option.value })}
              options={filterValues[currentFilter.key].map(x => ({ value: x, label: x }))}
              clearable={false}
              closeOnSelect
              removeSelected={false}
              className="react-select report-filter__form-component"
              placeholder="Select filter..."
              classNamePrefix="react-select"
            />
          </>
        );
      }
      case 'date': {
        return (
          <div className="date-picker">
            <DatePicker
              className="form__form-group-datepicker"
              selected={(currentFilter?.value && new Date(currentFilter.value)) ?? null}
              onChange={value => setCurrentFilter({ 
                ...currentFilter, 
                value: new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0)).toJSON(),
              })}
              dateFormat="dd/MM/yyyy"
              dropDownMode="select"
              popperPlacement="center"
              customInput={<Input className="react-table__input-field report-filter__input" />}
              placeholderText="Select date..."
            />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  const closeForm = () => {
    setCurrentFilter({ value: '' });
    setIsAdding(false);
    setIsUpdating(false);
  };

  const addFilter = () => {
    setSelectedFilters(selectedFilters.concat({ ...currentFilter }));
    closeForm();
  };

  const updateFilter = () => {
    const keys = [currentFilter.key, currentFilter.previousKey];
    setSelectedFilters(
      selectedFilters.map(x => (keys.includes(x.key) ? { ...currentFilter } : x)),
    );
    closeForm();
  };

  const renderButtons = () => (
    <div className="report-filter__form-component">
      {(isAdding || isUpdating) ? (
        <>
          {isAdding 
            ? <Button color="success" onClick={addFilter} disabled={!currentFilter?.value}>Add</Button>
            : <Button color="success" onClick={updateFilter} disabled={!currentFilter?.value}>Update</Button>}
          {(isAdding || isUpdating) && <Button onClick={closeForm}>Cancel</Button>}
        </>
      ) : (
        <Button color="primary" onClick={() => setIsAdding(true)}>Add filter</Button>
      )}
    </div>
  );

  const renderNewFilterForm = () => (
    <>
      <Select
        name="filter-option"
        value={currentFilter?.key ? { value: currentFilter.key, label: currentFilter.Header } : ''}
        onChange={onFilterSelected}
        options={(tableHeaderData || []).map(x => ({ value: x.key, label: x.Header }))}
        clearable={false}
        closeOnSelect
        removeSelected={false}
        className="react-select report-filter__form-component"
        placeholder="Select filter..."
        classNamePrefix="react-select"
      />
    </>
  );

  return (
    <div className="report-filter">
      <Container>
        <Card>
          <CardBody>
            <Row>
              <Col md={4}>
                <Input 
                  className="report-filter__input" 
                  type="text" 
                  placeholder="Enter a report name..."
                  value={reportName}
                  onChange={event => setReportName(event.target.value)}
                />

                <div className="report-filter__checkbox-wrapper report-filter__form-component">
                  <Input
                    className="report-filter__checkbox"
                    type="checkbox" 
                    checked={isSystemReport}
                    name="isSystemReport"
                    onChange={() => setIsSystemReport(!isSystemReport)}
                  />
                  <Label for="isSystemReport">System report</Label>
                </div>
                {(isAdding || isUpdating) && renderNewFilterForm()}
                {currentFilter && renderInput(currentFilter)}
                {renderButtons()}
              </Col>
              <Col>
                <SelectedFilters
                  selectedFilters={selectedFilters} 
                  tableHeaderData={tableHeaderData}
                  handleRemove={onRemoveFilter}
                  handleUpdate={onUpdateFilter}
                />
                <Button
                  color="success" 
                  onClick={onSaveReport} 
                  disabled={reportName.length === 0}
                >
                  Save report
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

ReportFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filterValues: PropTypes.object.isRequired,  
  // eslint-disable-next-line react/forbid-prop-types
  queryFilters: PropTypes.object.isRequired,  
  handleSubmit: PropTypes.func.isRequired,
  reportName: PropTypes.string,
  setReportName: PropTypes.func.isRequired,
  isSystemReport: PropTypes.bool,
  setIsSystemReport: PropTypes.func.isRequired,
  reportType: ReportType,
};

ReportFilter.defaultProps = {
  reportName: '',
  isSystemReport: false,
  reportType: ReportType.Attendee,
};

export default ReportFilter;
