import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../../../shared/components/auth/withAuth';

function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => (
        auth.isAuthenticated ? (
          children
        ) : (
          <Redirect 
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      )}
    />
  );
}

PrivateRoute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
};

PrivateRoute.defaultProps = {
    location: {},
    children: {},
};

export default PrivateRoute;
