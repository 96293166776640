import React from 'react';
import PropTypes from 'prop-types';
import SortIcon from 'mdi-react/SortIcon';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Input } from 'reactstrap';

const onSort = (currentColumn, sortedColumn, isCurrentColumn, handleSort) => {
  let sortDirection = '';

  if (isCurrentColumn) {
    sortDirection = sortedColumn.sortDirection === 'asc' ? 'desc' : 'asc';
  } else {
    sortDirection = 'asc';
  }

  return () => handleSort({ 
    sortByProperty: isCurrentColumn ? sortedColumn.sortByProperty : currentColumn.key,
    sortDirection,
  });
};

const Header = ({
 column, isSortable, handleSort, sortedColumn, 
}) => (
  <span className="react-table__column-header react-table__column-header__container">
    <span className={isSortable ? 'react-table__column-header sortable' : ''}>
      {column.render('Header')}
    </span>
    {isSortable && <Sorting column={column} handleSort={handleSort} sortedColumn={sortedColumn} />}
  </span>
);

Header.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.string,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    render: PropTypes.func,
    canSort: PropTypes.bool,
  }).isRequired,
  isSortable: PropTypes.bool.isRequired,
  handleSort: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sortedColumn: PropTypes.object.isRequired,
};

const Sorting = ({ column, handleSort, sortedColumn }) => {
  const isCurrentColumn = column.key === sortedColumn.sortByProperty;
  const direction = sortedColumn.sortDirection;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span 
      className="react-table__column-header sortable" 
      onClick={onSort(column, sortedColumn, isCurrentColumn, handleSort)}
      onKeyPress={onSort(column, sortedColumn, isCurrentColumn, handleSort)}
    >
      {!isCurrentColumn || !direction ? (
        <SortIcon />
      ) : (
        <span>
          {direction === 'desc'
            ? <SortDescendingIcon />
            : <SortAscendingIcon />}
        </span>
      )}
    </span>
  );
};

Sorting.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.string,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    key: PropTypes.string,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
  }).isRequired,
  handleSort: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sortedColumn: PropTypes.object.isRequired,
};

const getStylesResizable = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
];

const renderInputType = (column, filter, handleFiltersUpdate, filterOptions) => {
  switch (column.filterType) {
    case 'dropdown': {
      return (
        <Select
          isMulti
          name="name"
          value={(filter?.value || []).map(x => ({ value: x.value, label: x.value }))}
          onChange={newValue => handleFiltersUpdate(column.key, newValue, column.filterType)}
          options={(filterOptions || []).map(x => ({ value: x, label: x }))}
          clearable={false}
          closeOnSelect={false}
          removeSelected={false}
          className="react-select"
          placeholder="Select option..."
          classNamePrefix="react-select"
        />
      );
    }
    case 'date': {
      return (
        <div className="date-picker">
          <DatePicker
            className="form__form-group-datepicker"
            selected={filter?.value?.length && filter?.value[0].value && new Date(filter?.value[0].value)}
            onChange={newValue => handleFiltersUpdate(
              column.key, 
              [{ 
                value: newValue 
                ? new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate(), 0, 0, 0)).toJSON() 
                : undefined,
              }], 
              column.filterType,
            )}
            dateFormat="dd/MM/yyyy"
            dropDownMode="select"
            popperPlacement="center"
            customInput={<Input className="react-table__input-field" />}
            placeholderText="Select date..."
          />
        </div>
      );
    }
    case 'text': {
      return (
        <Input
          className="react-table__input-field"
          value={filter?.value[0].value || ''}
          onChange={e => handleFiltersUpdate(column.key, [{ value: e.target.value }], column.filterType)}
          placeholder="Enter text..."
        />
      );
    }
    default: {
      return <div style={{ height: 38 }} />;
    }
  }
};

const ReactTableHeader = ({
  headerGroups, 
  isResizable, 
  isSortable, 
  filters, 
  handleFiltersUpdate, 
  filterValues,
  handleSort,
  sortedColumn,
}) => {
  const headerPropsSortable = (props, { column }) => {
    if (column.getSortByToggleProps && isSortable) {
      return column.getSortByToggleProps;
    }
    return { column };
  };
  const headerPropsResize = (props, { column }) => {
    if (column.getResizerProps) {
      return getStylesResizable(props, column.align);
    }
    return { column };
  };

  return (
    <thead className="thead th">
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()} className="react-table thead tr">
          {headerGroup.headers.map(column => (
            <th
              {...column.getHeaderProps(headerPropsResize)}
              {...column.getHeaderProps()}
            >
              <div className={column.sticky ? '' : 'th__content'}>
                <div {...column.getHeaderProps(headerPropsSortable)}>
                  <Header
                    column={column}
                    isSortable={column.canSort}
                    handleSort={handleSort}
                    sortedColumn={sortedColumn}
                  />
                  {isResizable && (
                    <div {...column.getResizerProps()} className={`resizer ${column.isResizing && 'isResizing'}`} />
                  )}
                </div>
                <div>
                  {renderInputType(column, filters[column.key], handleFiltersUpdate, filterValues[column.key])}
                </div>
              </div>
            </th>
            ))}
        </tr>
        ))}
    </thead>
  );
};

ReactTableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.shape()),
    getHeaderGroupProps: PropTypes.func,
    getFooterGroupProps: PropTypes.func,
  })).isRequired,
  isResizable: PropTypes.bool.isRequired,
  isSortable: PropTypes.bool.isRequired,
  filters: PropTypes.objectOf(PropTypes.shape()).isRequired,
  handleFiltersUpdate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterValues: PropTypes.object.isRequired,
  handleSort: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sortedColumn: PropTypes.object.isRequired,
};

export default ReactTableHeader;
