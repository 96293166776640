import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import env from '../../../Env';
import { get } from '../../../utils/api/base';
import { ReportType } from '../../../shared/components/filter/ReportFilter';

const SidebarContent = ({ onClick, sidebarCollapse }) => {
  const [attendeeReportLinks, setAttendeeReportLinks] = useState([]);
  const [paymentReportLinks, setPaymentReportLinks] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    get(`${env.baseApiUrl}/api/Reports`)
      .then((res) => {
        setAttendeeReportLinks(res.data.filter(x => x.reportType === ReportType.Attendee));
        setPaymentReportLinks(res.data.filter(x => x.reportType === ReportType.Payment));
        setLoading(false);
      });
  };

  useEffect(() => fetchData(), []);

  const hideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink
          title="Home"
          icon="home"
          route="/dashboard"
          onClick={hideSidebar}
        />
        {loading ? (
          <div>Loading reports...</div>
        ) : (
          <>
            <SidebarCategory title="Attendee Reports" icon="list" sidebarCollapse={sidebarCollapse}>
              {attendeeReportLinks.map(link => (
                <SidebarLink 
                  key={link.adminReportId} 
                  title={link.reportName} 
                  route={link.reportUrl} 
                  onClick={hideSidebar}
                  adminReportId={link.adminReportId}
                  clientId={link.clientId}
                  isSystemReport={link.isSystemReport}
                  reloadSidebar={fetchData}
                />
              ))}
              <hr />
              <SidebarLink
                title="Create attendee report" 
                route="/create-attendee-report" 
                onClick={hideSidebar}
                reloadSidebar={fetchData}
              />
            </SidebarCategory>          
            <SidebarCategory title="Payment Reports" icon="list" sidebarCollapse={sidebarCollapse}>
              {paymentReportLinks.map(link => (
                <SidebarLink 
                  key={link.adminReportId} 
                  title={link.reportName} 
                  route={link.reportUrl} 
                  onClick={hideSidebar}
                  adminReportId={link.adminReportId}
                  clientId={link.clientId}
                  isSystemReport={link.isSystemReport}
                  reloadSidebar={fetchData}
                />
              ))}
              <hr />
              <SidebarLink
                title="Create payment report" 
                route="/create-payment-report" 
                onClick={hideSidebar}
                reloadSidebar={fetchData}
              />
            </SidebarCategory>
          </>
        )}
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
