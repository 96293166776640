import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
 Col, Container, Row, Button, 
} from 'reactstrap';
import DataReactTable from './components/DataReactTable';
import CreateTableData, { CreatePaymentTableData } from '../CreateData';
import env from '../../../Env';
import { get, post } from '../../../utils/api/base';
import AttendeeNoteModal from './components/AttendeeNoteModal';
import ReportFilter, { ReportType } from '../../../shared/components/filter/ReportFilter';
import history from '../../../utils/history';
import './index.scss';

const getFilterParams = filters => Object.entries(filters).reduce((params, filter) => {
  const key = filter[0];
  const values = filter[1]?.value;

  if (values?.length && values[0].value) {
    return `${params}${values.reduce((listParams, current) => {
      if (current.value) {
        return `${listParams}${key}=${current.value}&`;
      }
      
      return listParams;
    }, '')}`;
  } 

  return params;
}, '');

const getQueryFilters = (tableData) => {
  const filters = {};
  const urlSearchParams = new URLSearchParams(window.location.search);

  urlSearchParams.forEach((paramValue, paramKey) => {
    if (paramKey !== 'adminReportId' && paramKey !== 'reportType') {
      const filter = tableData.filters.find(el => el.key === paramKey);

      if (filter) {
        const ft = filter.filterType;
        const filterValues = [
          {
            value: paramValue,
          },
        ];
    
        if (filters[paramKey]) {
          const { value, filterType } = filters[paramKey];
          filters[paramKey] = { value: filterValues.concat(value), filterType };
        } else {
          filters[paramKey] = { value: filterValues, filterType: ft };
        } 
      }
    }
  });

  return filters;
};

const getClientId = urlParams => urlParams?.split('?')[0];

const capitalise = word => word.charAt(0).toUpperCase() + word.slice(1);

const getSortParams = (sortedColumn) => {
  const sortByProperty = capitalise(sortedColumn.sortByProperty);

  return `sortByProperty=${sortByProperty}&sortDirection=${sortedColumn.sortDirection}`;
};

const DataTable = () => {
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [currentlySelectedAttendee, setCurrentlySelectedAttendee] = useState('');

  const toggleAttendeeNotesModal = (payceEventAttendeeId) => {
    setCurrentlySelectedAttendee(payceEventAttendeeId);
    setNoteModalVisible(payceEventAttendeeId !== null);
  };

  const searchParams = new URLSearchParams(window.location.search);
  const adminReportId = searchParams.get('adminReportId');
  const [currentId, setCurrentId] = useState(adminReportId);

  const reportType = +(searchParams.get('reportType'));

  const attendeeReportTableData = CreateTableData(toggleAttendeeNotesModal);
  const paymentReportTableData = CreatePaymentTableData();
  const [tableData, setTableData] = useState(reportType === ReportType.Attendee
      ? attendeeReportTableData : paymentReportTableData);

  const [totalRecords, setTotalRecords] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  
  const [reportDetailLoading, setReportDetailLoading] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);

  const [page, setPage] = useState(1);

  const attendeeFilters = getQueryFilters(CreateTableData(toggleAttendeeNotesModal));
  const paymentFilters = getQueryFilters(CreatePaymentTableData());
  const [filters, setFilters] = useState(reportType === ReportType.Attendee ? attendeeFilters : paymentFilters);

  const sortByProperty = reportType === ReportType.Attendee ? 'startDate' : 'datePaidUtc';
  const [sortedColumn, setSortedColumn] = useState({ sortByProperty, sortDirection: 'desc' });

  const { urlParams } = useParams();
  const clientId = getClientId(urlParams);

  const [originalReportName, setOriginalReportName] = useState(''); 
  const [originalIsSystemReport, setOriginalIsSystemReport] = useState(false); 

  const location = useLocation();

  const [shouldFetch, setShouldFetch] = useState(true);
  const [showReportFilters, setShowReportFilters] = useState(false);

  const setHasNotesForCurrentAttendee = (hasNotes) => {
    tableData.tableRowsData.filter(x => x.payceEventAttendeeId === currentlySelectedAttendee)[0].hasNotes = hasNotes;
  };

  useEffect(() => {
    if (currentId !== adminReportId) {
      setCurrentId(adminReportId);
      setFilters(reportType === ReportType.Attendee ? attendeeFilters : paymentFilters);
      setTableData(reportType === ReportType.Attendee ? attendeeReportTableData : paymentReportTableData);
      setPage(1);
      setOriginalReportName('');
      setShowReportFilters(false);
      setSortedColumn({ 
        ...sortedColumn,
        sortByProperty: reportType === ReportType.Attendee ? 'startDate' : 'datePaidUtc',
      });
      setShouldFetch(true);
    }
  }, [adminReportId, currentId, attendeeFilters, paymentFilters, attendeeReportTableData,
    paymentReportTableData, reportType, sortedColumn]);

  useEffect(() => {
    const fetchData = async () => {
      setReportDetailLoading(true);

      const endpoint = encodeURI(`${env.baseApiUrl}/api/reports/${clientId}/${adminReportId}`);

      get(endpoint)
      .then((res) => {
        const { data } = res;
        
        setOriginalReportName(data.reportName);
        setOriginalIsSystemReport(data.isSystemReport);

        setReportDetailLoading(false);
      });
    };

    fetchData();
  }, [clientId, adminReportId]);

  useEffect(() => {
    const fetchData = async () => {
      setResultsLoading(true);
      
      const urlFilters = getFilterParams(filters);
      const urlSort = getSortParams({ 
        ...sortedColumn,
        sortByProperty: reportType === ReportType.Attendee ? 'startDate' : 'datePaidUtc',
      }); 

      const queryString = `?reportType=${reportType}&page=${page}&${urlSort}&${urlFilters}`;
      const endpoint = encodeURI(`${env.baseApiUrl}/api/reports/${clientId}${queryString}`);

      get(endpoint)
      .then((res) => {
        const { data } = res;
        const tableRowsData = data.rows.map(x => (
          { 
            ...x, 
            eventName: { 
              value: x.eventName, 
              link: x.payceEventId !== null && (x.imported === undefined || x.imported === 'False')
                ? `https://${x.clientDomain}/portal#/events/${x.payceEventId}` : '',
            },
            payceEventId: x.payceEventId === '00000000-0000-0000-0000-000000000000'
              ? 'N/A' : x.payceEventId,
          }
        ));

        setTableData(previousTableData => ({ ...previousTableData, tableRowsData }));
        setTotalRecords(data.totalRecords);
        setFilterValues(data.filterValues);
        setResultsLoading(false);
      });
    };

    if (shouldFetch) {
      fetchData();
      setShouldFetch(false);
    }
  }, [clientId, page, filters, adminReportId, sortedColumn, shouldFetch, reportType]);

  useEffect(() => {
    setShouldFetch(true);
  }, [page]);

  const { t } = useTranslation('common');

  const handleFiltersUpdate = (key, value, filterType) => {
    const newFilters = { ...filters };
    newFilters[key] = { value, filterType };

    const adminReportParam = adminReportId ? `?adminReportId=${adminReportId}&reportType=${reportType}&` : '';
    const queryParameters = getFilterParams(newFilters);

    history.push({
      search: adminReportParam + queryParameters,
    });

    setFilters(newFilters);
    setPage(1);
    setShouldFetch(true);
  };

  const handleReportWizardSubmit = (name, isSystemReport, selectedFilters) => {
    setReportDetailLoading(true);

    setOriginalReportName(name);
    setOriginalIsSystemReport(isSystemReport);

    const adminReportParam = adminReportId ? `?adminReportId=${adminReportId}&reportType=${reportType}&` : '';
    const queryParameters = selectedFilters.reduce((acc, { key, value }) => `${acc}${key}=${value}&`, '');

    const basePath = location.pathname.split('?')[0];

    history.push({
      pathname: `${basePath}`,
      search: adminReportParam + queryParameters,
    });

    const endpoint = encodeURI(`${env.baseApiUrl}/api/reports/${clientId}`);

    const body = JSON.stringify({ 
      name, 
      isSystemReport, 
      queryParameters, 
      clientId,
      adminReportId,
    });

    post(endpoint, body).then(location.reportProps.reloadSidebar);

    const newFilters = selectedFilters.reduce((acc, cur) => {
      if (acc[cur.key]) {
        acc[cur.key] = {
          filterType: acc[cur.key].filterType,
          value: acc[cur.key].concat(cur.value),
        };
      } else {
        acc[cur.key] = {
          filterType: cur.filterType,
          value: [{ value: cur.value }],
        };
      }
  
      return acc;
    }, {});
    
    setFilters(newFilters);
    setShouldFetch(true);
    setReportDetailLoading(false);
  };

  const handleExport = () => {
    const urlFilters = getFilterParams(filters);
    const urlSort = getSortParams(sortedColumn); 

    // eslint-disable-next-line max-len
    const endpoint = encodeURI(`${env.baseApiUrl}/api/reports/${clientId}/export?${urlSort}&reportType=${reportType}&${urlFilters}`);

    const body = JSON.stringify({
      clientId,
      adminReportId,
    });

    const options = {
      responseType: 'blob',
    };

    post(endpoint, body, options)
      .then((res) => {
        const file = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', 'report.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSort = (sortData) => {
    setSortedColumn(sortData);
    setShouldFetch(true);
  };

  return (
    <Container className="admin-report">
      <Row>
        <Col md={6}>
          <h3 className="page-title">{t('Reports')}</h3>
        </Col>
        <Col md={6}>
          <div className="admin-report__button-group-right">
            <Button 
              style={{ marginRight: '15px' }} 
              color="primary" 
              onClick={() => setShowReportFilters(!showReportFilters)}
            >
              {showReportFilters ? 'Hide filters' : 'Show filters'}
            </Button>
            <Button
              color="success" 
              onClick={handleExport} 
              download="report.csv"
            >
              Export report
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        {!reportDetailLoading && showReportFilters && (
          <ReportFilter
            filterValues={filterValues} 
            queryFilters={filters} 
            handleSubmit={handleReportWizardSubmit}
            reportName={originalReportName}
            setReportName={setOriginalReportName}
            isSystemReport={originalIsSystemReport}
            setIsSystemReport={setOriginalIsSystemReport}
          />
        )}
      </Row>
      <Row>
        <DataReactTable 
          reactTableData={tableData} 
          page={page} 
          setPage={setPage} 
          totalRecords={totalRecords}
          loading={resultsLoading}
          filters={filters}
          handleFiltersUpdate={handleFiltersUpdate}
          filterValues={filterValues}
          handleSort={handleSort}
          sortedColumn={sortedColumn}
        />
      </Row>
      <AttendeeNoteModal 
        visible={noteModalVisible} 
        toggleVisiblity={toggleAttendeeNotesModal} 
        payceEventAttendeeId={currentlySelectedAttendee} 
        updateHasNotes={setHasNotesForCurrentAttendee}
      />
    </Container>
  );
};

export default DataTable;
