import React, { useEffect, useState } from 'react';
import {
 Card, CardBody, Col, Container, Row, 
} from 'reactstrap';
import Select from 'react-select';
import env from '../../../Env';
import { get } from '../../../utils/api/base';
import './index.scss';
import PreEventEngagements from './components/PreEventEngagements';
import PostEventEngagements from './components/PostEventEngagements';

const AdminDashboard = () => {
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState({ value: '', label: '' });

  useEffect(() => {
    const fetchData = async () => {
      const endpoint = encodeURI(`${env.baseApiUrl}/api/reports/clients`);

      get(endpoint).then(res => setClientOptions(res.data.map(x => ({ ...x }))));
    };

    fetchData();
  }, []);

  return (
    <Container className="admin-dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dashboard</h3>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col md={3}>
              <h5 className="page-title heading">Select client</h5>
              <Select
                name="value"
                value={selectedClient.value ? selectedClient : ''}
                onChange={option => setSelectedClient(option)}
                options={clientOptions.map(x => ({ value: x.id, label: x.domain }))}
                clearable={false}
                closeOnSelect
                removeSelected={false}
                className="react-select"
                placeholder="Select filter..."
                classNamePrefix="react-select"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              {selectedClient.value && <PreEventEngagements clientId={selectedClient.value} />}
            </Col>
            <Col lg={8}>
              {selectedClient.value && <PostEventEngagements clientId={selectedClient.value} />}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AdminDashboard;
