/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import axios from 'axios';
import history from '../../history';
import env from '../../../Env';

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.withCredentials = true;
    config.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return config;
  },
);

axiosApiInstance.interceptors.response.use(response => response, async (error) => {
  const originalRequest = error.config;
  if (error.response?.status === 401 && originalRequest.url === `${env.baseApiUrl}/api/authentication/refresh`) {
    localStorage.removeItem('isAuthenticated');
    history.push('/login');
    return Promise.reject(error);
  }

  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    return axiosApiInstance.post(`${env.baseApiUrl}/api/authentication/refresh`).then((res) => {
      if (res.status === 200) {
        return axiosApiInstance(originalRequest);
      }

      return Promise.reject(error);
    }).catch((res) => {
      localStorage.removeItem('isAuthenticated');
      history.push('/login');
      return Promise.reject(error);
    });
  }

  return Promise.reject(error);
});

export default axiosApiInstance;
