import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import LogInForm from '@/shared/components/loginForm/LogInForm';
import env from '../../../Env';
import { useAuth } from '../../../shared/components/auth/withAuth';
import fetchConfig from '../../../config/fetchConfig';
import { post } from '../../../utils/api/base';

const LogIn = () => {
  const [apiResponse, setApiResponse] = useState({});

  const auth = useAuth();

  const handleSubmit = (payload) => {
    post(`${env.baseApiUrl}/api/authentication/login`, JSON.stringify(payload))
    .then((res) => {
      setApiResponse(res.data);
    });
  };

  if (apiResponse.success) {
    auth.signin();

    return <Redirect to="/dashboard" />;
  }

  if (auth?.isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <span className="account__head" />
          <LogInForm
            onSubmit={handleSubmit}
            form="log_in_form"
          />
        </div>
      </div>
    </div>
  );
};

export default LogIn;
