import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './EngagementTable.scss';
import env from '../../../../Env';
import { get } from '../../../../utils/api/base';

const rows = [
  {
    days: '1 - 7',
    key: 'oneToSeven', 
  },
  {
    days: '7 - 14',
    key: 'sevenToFourteen', 
  },
  {
    days: '14+',
    key: 'fourteenPlus', 
  },
];

const PreEventEngagements = ({ clientId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(null);

    const fetchData = async () => {
      setLoading(true);

      const endpoint = encodeURI(`${env.baseApiUrl}/api/reports/${clientId}/pre-events`);

      get(endpoint).then((res) => {
        setData(res.data);
        setLoading(false);
      });
    };

    fetchData();
  }, [clientId]);

  const summedTotal = (data && rows.reduce((total, row) => total + data[row.key]?.total, 0)) || 0;

  return (
    <div className="engagements-wrapper table react-table">
      <table className="react-table">
        <thead>
          <tr>
            <th colSpan="3">
              <h4 className="table__title">Pre-event attendees ({summedTotal})</h4>
            </th>
          </tr>
          <tr className="table__columns-row">
            <th>DAYS</th>
            <th>AMOUNT</th>
            <th>REQUIRING ACTION</th>
          </tr>
        </thead>
        <tbody>
          {data && rows.map((row) => {
            const { total, counts } = data[row.key];

            const unsigned = counts.find(count => count.status === 'ContractIssued');
            const draft = counts.find(count => count.status === 'DraftContract');
            const added = counts.find(count => count.status === 'Added');
            const awaitingRelease = counts.find(count => count.status === 'AwaitingPreApproval');

            return (
              <tr key={row.key}>
                <td className="table__bold-cell">{row.days}</td>
                <td>{total}</td>
                <td>
                  <Link to={`/reports/${clientId}?${unsigned.queryString}`}>
                    {`${unsigned.count} unsigned; `}
                  </Link>
                  <Link to={`/reports/${clientId}?${draft.queryString}`}>
                    {`${draft.count} in draft; `}
                  </Link>
                  <Link to={`/reports/${clientId}?${added.queryString}`}>
                    {`${added.count} in added; `}
                  </Link>
                  <Link to={`/reports/${clientId}?${awaitingRelease.queryString}`}>
                    {`${awaitingRelease.count} awaiting release`}
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {loading && <div>Loading data...</div>}
    </div>
  );
};

PreEventEngagements.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default PreEventEngagements;
