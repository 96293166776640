import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './EngagementTable.scss';
import env from '../../../../Env';
import { get } from '../../../../utils/api/base';

const rows = [
  {
    days: '0 - 14',
    key: 'oneToFourteen', 
  },
  {
    days: '14+',
    key: 'fourteenPlus', 
  },
  {
    days: 'Total',
    key: 'totals', 
  },
];

const columns = [
  'awaitingSignature', 
  'awaitingConfirmation',
  'awaitingExpensesAndInvoice',
  'awaitingApproval',
  'approved',
  'paymentRejected',
  'paymentProcessing',
];

const PostEventEngagements = ({ clientId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(null);

    const fetchData = async () => {
      setLoading(true);

      const endpoint = encodeURI(`${env.baseApiUrl}/api/reports/${clientId}/post-events`);

      get(endpoint).then((res) => {
        setData(res.data);
        setLoading(false);
      });
    };

    fetchData();
  }, [clientId]);

  const summedTotal = (data && data.totals.total) || 0;

  return (
    <div className="engagements-wrapper table react-table">
      <table className="react-table">
        <thead>
          <tr>
            <th colSpan="8">
              <h4 className="table__title">Post-event attendees ({summedTotal})</h4>
            </th>
          </tr>
          <tr className="table__columns-row">
            <th>DAYS</th>
            <th>AWAITING SIGNATURE</th>
            <th>AWAITING CONFIRMATION OF SERVICES</th>
            <th>AWAITING EXPENSES/INVOICE</th>
            <th>AWAITING APPROVAL</th>
            <th>APPROVED</th>
            <th>PAYMENT REJECTED</th>
            <th>PAYMENT PROCESSING</th>
          </tr>
        </thead>
        <tbody>
          {data && rows.map((row) => {
            const rowData = data[row.key];

            const cells = columns.map(column => 
              ({ key: column, ...rowData.counts.find(count => count.status === column) }));

            return (
              <tr key={row.key}>
                <td className="table__bold-cell">{row.days}</td>
                {cells.map(cell => (
                  <td key={cell.key}>
                    {row.key !== rows[rows.length - 1].key ? (
                      cell.count || 0
                    ) : (
                      <Link to={`/reports/${clientId}?${cell.queryString}`}>{cell.count || 0}</Link>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {loading && <div>Loading data...</div>}
    </div>
  );
};

PostEventEngagements.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default PostEventEngagements;
