import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { Button } from 'reactstrap';
import moment from 'moment';
import './SelectedFilters.scss';

const SelectedFilters = ({ selectedFilters, handleRemove, handleUpdate }) => {  
  const columns = useMemo(() => [
    {
      Header: 'Filter',
      accessor: 'filter',
      key: 'filter',
    },
    {
      Header: 'Value',
      accessor: 'value',
      key: 'value',
    },
    {
      id: 'button',
      accessor: 'filter',
      Cell: data => (
        <div className="selected-filters__btn-wrapper">
          <Button
            className="selected-filters__btn"
            onClick={() => handleUpdate(data.row.original.key)}
            color="primary"
          >
            Update
          </Button>
          <Button
            className="selected-filters__btn"
            onClick={() => {
              handleRemove(data.row.original.key, data.row.original.value);
            }}
            color="danger"
          >
            Remove
          </Button>
        </div>
      ),
    },
  ], [handleRemove, handleUpdate]);

  const data = selectedFilters.map(x => ({ filter: x.Header, ...x }));

  const {
    headerGroups,
    rows,
    getTableProps,
    getTableBodyProps,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  
  const renderCell = (row, cell) => (row.original.filterType === 'date' && cell.column.key === 'value' 
    ? moment(cell.value).format('DD/MM/yyyy') 
    : cell.render('Cell'));

  return (
    <div className="selected-filters table react-table">
      <table className="react-table" {...getTableProps()}>
        <thead className="thead th">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} className="react-table thead tr">
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
          prepareRow(row);
          
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>
                  {renderCell(row, cell)}
                </td>
              ))}
            </tr>
          );
        })}
        </tbody>
      </table>
    </div>
  );
};

SelectedFilters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedFilters: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableHeaderData: PropTypes.array.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default SelectedFilters;
