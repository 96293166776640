import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import Layout from '../../Layout/index';
import NotFound404 from '../../DefaultPage/404/index';
import AdminDashboard from '../../Dashboards/AdminDashboard/index';

import LogIn from '../../Account/LogIn';
import PrivateRoute from './PrivateRoute';
import { useAuth } from '../../../shared/components/auth/withAuth';
import DataTable from '../../Tables/DataTable';
import CreateReport from '../../Dashboards/CreateReport';
import CreatePaymentReport from '../../Dashboards/CreatePaymentReport';

const Router = () => {
  const auth = useAuth();
  const location = useLocation();

  return (
    <MainWrapper>
      <main>
        {auth?.isAuthenticated && <Layout />}
        
        <div className={location?.pathname === '/login' ? '' : 'container__wrap'}>
          <Switch>
            <Route exact path="/login" component={LogIn} />
            
            <PrivateRoute exact path="/">
              <AdminDashboard />
            </PrivateRoute>
            <PrivateRoute exact path="/dashboard">
              <AdminDashboard />
            </PrivateRoute>
            <PrivateRoute exact path="/reports/:urlParams">
              <DataTable />
            </PrivateRoute>
            <PrivateRoute exact path="/create-attendee-report">
              <CreateReport />
            </PrivateRoute>
            <PrivateRoute exact path="/create-payment-report">
              <CreatePaymentReport />
            </PrivateRoute>
            <PrivateRoute>
              <NotFound404 />
            </PrivateRoute>
          </Switch>
        </div>
      </main>
    </MainWrapper>
);
 };

export default Router;
