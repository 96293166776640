import React, { useState } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';

const email = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined);
    
const required = value => (value || typeof value === 'number' ? undefined : 'Required');

const LogInForm = ({
  handleSubmit, errorMessage, errorMsg, fieldEmail, typeFieldEmail,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Form className="form login-form" onSubmit={handleSubmit}>
      <Alert
        color="danger"
        isOpen={!!errorMessage || !!errorMsg}
      >
        {errorMessage}
        {errorMsg}
      </Alert>
      <div className="form__form-group">
        <span className="form__form-group-label">{fieldEmail}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="emailAddress"
            component="input"
            type={typeFieldEmail}
            placeholder={fieldEmail}
            className="input-without-border-radius"
            validate={email}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password"
            component="input"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            className="input-without-border-radius"
            validate={required}
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword ? ' active' : ''}`}
            onClick={showPasswordToggle}
          >
            <EyeIcon />
          </button>
        </div>
      </div>
      <div className="account__btns">
        <Button className="account__btn" type="submit" color="primary">Sign In</Button>
      </div>
    </Form>
  );
};

LogInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  fieldEmail: PropTypes.string,
  typeFieldEmail: PropTypes.string,
};

LogInForm.defaultProps = {
  errorMessage: '',
  errorMsg: '',
  fieldEmail: 'Email Address',
  typeFieldEmail: 'email',
};

export default connect(state => ({
  errorMsg: state.user.error,
}))(reduxForm()(LogInForm));
