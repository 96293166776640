import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon';
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

const PaginationContainer = ({ page, setPage, totalRecords }) => {
  const canGoBack = page !== 1;

  const lastPage = Math.ceil(totalRecords / 100);
  const canGoForward = lastPage && page !== lastPage;

  return (
    <Pagination className="pagination" dir="ltr">
      <div className="pagination">
        <PaginationLink
          className="pagination__link pagination__link--arrow"
          type="button"
          onClick={() => setPage(1)}
          disabled={!canGoBack}
        >
          <ChevronDoubleLeftIcon className="pagination__link-icon" />
        </PaginationLink>

        <PaginationLink
          className="pagination__link pagination__link--arrow"
          type="button"
          onClick={() => setPage(page - 1)}
          disabled={!canGoBack}
        >
          <ChevronLeftIcon className="pagination__link-icon" />
        </PaginationLink>

        <PaginationItem
          className="pagination__item"
          key={page}
        >
          <PaginationLink
            className="pagination__link"
            type="button"
          >
            {page}
          </PaginationLink>
        </PaginationItem>

        <PaginationLink
          className="pagination__link pagination__link--arrow"
          type="button"
          onClick={() => setPage(page + 1)}
          disabled={!canGoForward}
        >
          <ChevronRightIcon className="pagination__link-icon" />
        </PaginationLink>

        <PaginationLink
          className="pagination__link pagination__link--arrow"
          type="button"
          onClick={() => setPage(lastPage)}
          disabled={!canGoForward}
        >
          <ChevronDoubleRightIcon className="pagination__link-icon" />
        </PaginationLink>
      </div>
      <div>Total records: {totalRecords}</div>
    </Pagination>
  );
};

PaginationContainer.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

export default PaginationContainer;
